<template>
  <section id="BaseDateTimePicker">
    <v-icon class="pl-4 pr-4">{{prependIcon}}</v-icon>
    <v-datetime-picker
      outlined
      :label="label"
      v-model="selectedDate"
      class="v-input__control"
    >
      <template v-slot:dateIcon>
        <v-icon>mdi-calendar</v-icon>
      </template>
      <template v-slot:timeIcon>
        <v-icon>mdi-clock</v-icon>
      </template>
    </v-datetime-picker>
  </section>
</template>

<script>
// import moment from "moment"
// import { format, parseISO } from 'date-fns'

export default {
  name: "BaseDateTimePicker",
  props: {
    label: {
      type: String,
      default: ""
    },
    date: {
      type: Date,
      default: null
    },
    prependIcon: {
      type: String,
      default: "mdi-calendar"
    },
    format: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      selectedDate: null
    };
  },
  mounted() {}
};
</script>
