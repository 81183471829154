<template>
  <v-menu
    v-model="menu2"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="computedDateFormatted"
        :label="label"
        outlined
        :append-icon="prependIcon"
        readonly
        v-bind="attrs"
        v-on="on"
        @blur="parseDate(selectedDate)"
        hide-details
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="selectedDate"
      no-title
      :readonly="readonly"
      @input="menu2 = false"
      :min="min"
      :max="max"
    ></v-date-picker>
  </v-menu>
  <!-- <v-text-field
        v-model="selectedDate"
        :
        persistent-hint
        :prepend-inner-icon="prependIcon"
        v-bind="attrs"
        @blur="parseDate(selectedDate)"
        v-on="on"
      ></v-text-field> -->
</template>

<script>
import moment from "moment";
export default {
  name: "DatePicker",
  props: {
    label: {
      type: String,
      default: "",
    },
    date: {
      type: String,
      default: null,
    },
    actualDate: {
      type: Date,
      default: null,
    },
    prependIcon: {
      type: String,
      default: "mdi-calendar",
    },
    format: {
      type: String,
      default: null,
    },
    min: {
      type: String,
      default: null,
    },
    max: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      selectedDate: null,
      menu2: false,
    };
  },
  watch: {
    date(propDate) {
      this.selectedDate = moment(propDate).format("YYYY-MM-DD");
    },
    selectedDate(newSelectedDate) {
      this.$emit("change", newSelectedDate);
    },
    actualDate(propDate) {
      this.selectedDate = moment(propDate).format("YYYY-MM-DD");
    },
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.selectedDate);
    },
  },
  methods: {
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      if (!month || !day || !year) return;

      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
  },
};
</script>
