import baseService from "@/services/base.service.js";

const patientlogService = {
  async getCategoryTypesList() {
    return await baseService.get(`Lookup/GetCategoryTypes`);
  },
  async getMonitorTypes() {
    return await baseService.post(`Lookup/GetMonitorTypes`);
  },
  async create(request) {
    return await baseService.post(`PatientLog/Create`, request);
  },
    async search(request) {
    return await baseService.post(`PatientLog/Search`, request);
  },
  async update(request) {
    return await baseService.post(`PatientLog/Update`, request);
    },
    async delete(request) {
        return await baseService.post(`PatientLog/Delete`, request);
    },
};

export default patientlogService;
