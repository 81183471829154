import services from "@/services";
import store from "@/store"
import { ToPageModel } from "./Models/PageModel";
import { ToSearchPageRequest } from "./Models/SearchPageRequest";

const patientModule = {
  namespaced: true,
  state: {
    loader: false,
    patient: {},
    patients: [],
    inpatientSessions: [],
    outpatientSessions: [],
    activeTab: null,
  },
  getters: {
    patient: state => state.patient,
    patients: state => state.patients,
    inpatientSessions: state => state.inpatientSessions,
    outpatientSessions: state => state.outpatientSessions,
    items: state => state.items,
    search: state => state.search,
    activeTab: state => state.activeTab,
  },
  actions: {
    async get_patient(context, patientId) {
      context.state.loading = true;

      return services.patientService
        .getPatient(patientId)
        .then(patientResponse => {
          context.commit("set_patient", patientResponse);
        })
        .finally(() => {
          context.state.loading = false;
        });
    },
    async getAvailablePatients(context, dataTablePaging) {
      var page = ToPageModel(dataTablePaging.options);
      var request = ToSearchPageRequest(dataTablePaging.searchText, page);

      if (dataTablePaging.statusList) {
        request["Statuses"] = dataTablePaging.statusList.map(item=> item.lookSessionStatusId);
      }

      request["PageModel"] = request.Page;

      return await services.patientService
        .getPatients(request)
        .then(response => {
          context.commit("set_patients", response.results);
          return response;
        })
        .finally(() => context.commit("set_loader", false));
    },
    async get_inpatient_sessions(context, dataTablePaging) {
      var page = ToPageModel(dataTablePaging.options);
      var request = ToSearchPageRequest(dataTablePaging.searchText, page);
      request.PatientId = dataTablePaging.patientId;
      return await services.patientService
        .getInpatientSessions(request)
        .then(response => {
          return response;
        });
    },
    async get_outpatient_sessions(context, dataTablePaging ) {
      context.commit("set_loader", true);

        var page = ToPageModel(dataTablePaging.options);
        var request = ToSearchPageRequest(dataTablePaging.searchText, page);
        request.PatientId = dataTablePaging.patientId;
      return await services.patientService
        .getOutpatientSessions(request)
        .then(response => {
          return response;
        })
        .finally(() => context.commit("set_loader", false));
    },
    get_session_logs(context, request) {
      return services.patientService.GetSessionLogs(request).then(response => {
        return response;
      });
    },
    get_all_session_logs(context, request) {
      return services.patientService.GetAllSessionLogs(request).then(response => {
        return response;
      });
    },
    update_patient(context, patient) {
      return services.patientService
        .updatePatient(patient)
        .then(response => {
          store.commit("snack/set_snack", "Patient Update Successful");
        })
        .catch(function(err) {
          if (err) {
            store.commit("snack/set_snack", err.message);
          } else {
            store.commit("snack/set_snack", "Patient Update Failed");
          }
        })
        .finally(() => (this.loading = false));
    },
  },
  mutations: {
    set_patient(state, response) {
      state.patient = response;
    },
    set_patients(state, response) {
      state.patients = response;
    },
    set_inpatients(state, response) {
      state.inpatientSessions = response;
    },
    set_outpatients(state, response) {
      state.outpatientSessions = response;
    },
    set_loader(state, loader) {
      state.loader = loader;
    },
    set_active_tab(state, activeTab) {
      state.activeTab = activeTab;
    }
  }
};

export default patientModule;

