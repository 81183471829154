import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@/scss/vuetify/overrides.scss";
Vue.use(Vuetify);

const theme = {
  primary: "#1e88e5",
  info: "#1e88e5",
  success: "#21c1d6",
  accent: "#fc4b6c",
  default: "#563dea",
};

export default new Vuetify({
  theme: {
    themes: {
      dark: theme,
      light: {
        primary: "#318EED",
        accent: "#E6C040",
        secondary: "#9A9A9A",
          success: "#9A9A9A",
        info: "#2196F3",
        warning: "#FB8C00",
        error: "#FF5252",
      },
    },
  },
});
