import baseService from "@/services/base.service.js"
import store from '../store'

    const authenticationService = {
        async validateLogin(credentials) {
            return await baseService.post(
              `Login/Authenticate`,
              credentials
            )
        },
        async login(credentials) {
            return await baseService.post(

                `Login/ValidateLoginCode`,
                credentials
            )
        },
        async logout() {
            return await baseService.post(
              `Login/SignOut`              
            )
        }
    }
    
    export default authenticationService;