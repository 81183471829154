import baseService from "@/services/base.service.js";

const patientService = {
  base: 'Patient',
  async deletePatient(patient) {
    return await baseService.post(`${this.base}/delete`, patient);
  },
  async getPatient(patientId) {
    return await baseService.get(`${this.base}/get?patientId=${patientId}`);
  },
  async getPatients(pagingRequest) {
    return await baseService.post(`${this.base}/getall`, pagingRequest);
  },
  async createPatient(patient) {
    return await baseService.post(`${this.base}/Create`, patient);
  },
  async updatePatient(patient) {
    return await baseService.post(`${this.base}/update`, patient);
  },
  async getAllPatients(patient) {
    return await baseService.post(`${this.base}/delete`, patient);
  },
  async getOutpatientSessions(request) {
    return await baseService.post(`OutpatientSession/GetOutpatientSessions`, request);
  },
  async getInpatientSessions(request) {
    return await baseService.post(`InpatientSession/getall`, request);
  },
  async GetSessionLogs(request) {
    return await baseService.post(`SessionLog/GetAllByPatientId`, request);
  },
  async GetAllSessionLogs(request) {
    return await baseService.post(`SessionLog/getall`, request);
  },
  async getInpatientSession(inpatientId) {
    return await baseService.get(`InpatientSession/get?inpatientId=${inpatientId}`);
  },
  async updateInpatientSession(inpatientSession) {
    return await baseService.post(`InpatientSession/update`, inpatientSession);
  },
  async getOutpatientSession(outpatientSessionId) {
    return await baseService.get(`OutpatientSession/get?outpatientSessionId=${outpatientSessionId}`);
  },
  async updateOutpatientSession(outpatientSession) {
    return await baseService.post(`OutpatientSession/update`, outpatientSession);
  },
  async getPatientStatuses(){
    return await baseService.post('Lookup/GetPatientStatuses')
  },
  async getSessionStatuses() {
    return await baseService.post('Lookup/GetSessionStatuses')
  },
  async getMyPatients() {
    return await baseService.get('Patient/GetAllMyPatients')
  }
};

export default patientService;