import services from "@/services";

const userModule = {
  namespaced: true,
  state: {
    profile: null,
    emailAddress: "",
    title: "",
    userPic: null,
    firstName: '',
    lastName: '',
    initials: '',
    fullName: '',
    isAdmin: false,
    isTech: false,
    isTechManager: false,
    isReadOnly: false,
  },
  getters: {
    userProfile: (state) => state.profile,
    emailAddress: (state) => state.emailAddress,
    title: (state) => state.title,
    userPic: (state) => state.userPic,
    firstName: (state) => state.firstName,
    lastName: (state) => state.lastName,
    initials: (state) => state.initials,
    fullName: (state) => state.fullName,
    isAdmin: (state) => state.isAdmin,
    isTech: (state) => state.isTech,
    isTechManager: (state) => state.isTechManager,
    isReadOnly: (state) => state.isReadOnly
  },
  actions: {
    getProfile(context) {
      this.loading = true;
      return services.userService
        .getUser(context)
        .then((response) => {
          context.commit("set_user", response);
          return response;
        })
        .finally(() => (this.loading = false));
    },
    getUserPic(context) {
      this.loading = true;
      return services.userService
        .getUserPic(context)
        .then((response) => {
          context.commit("add_pic", response);
          return response;
        })
        .finally(() => (this.loading = false));
    },
    getUserRoles(context) {
      services.userService
        .getUserRoles(context)
        .then((response) => {
          context.commit("set_roles", response);
        });
    },
  },
  mutations: {
    set_roles(state, roles) {
        state.isAdmin = roles.isAdmin;
        state.isTech = roles.isTechnician;
        state.isTechManager = roles.isTechnicianManager;
        state.isReadOnly = roles.isReadOnly;
    },
    set_user(state, response) {
      state.profile = response;

      if (response) {
        state.firstName = response.firstName;
        state.lastName = response.lastName;

        state.fullName = state.firstName + ' ' + state.lastName;

        state.initials = '';

        state.initials += response.firstName.charAt(0);
        state.initials += response.lastName.charAt(0);
        
        state.emailAddress = response.email;
        state.title = response.title;
      }
    },
    add_pic(state, response) {
      state.userPic = response.userPic;
    },
  },
};

export default userModule;
