<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="computedDateFormatted"
        label="Birthday"
        append-icon="mdi-calendar"
        readonly
        :disabled="isTech"
        outlined
        v-bind="attrs"
        v-on="on"
        hide-details
      ></v-text-field>
    </template>
    <v-date-picker
      ref="picker"
      v-model="date"
      :max="new Date().toISOString().substr(0, 10)"      
      min="1910-01-01"
      @change="save"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment"
  export default {
    name: 'BaseBirthDatePicker',
    props: {
        birthDate: {
            type: String,
            default: null
        }
    },
    data: () => ({
      date: null,
      menu: false,
    }),
    watch: {
      birthDate (val) {
          this.date = moment(val).toISOString().substr(0, 10);
      },
      menu (val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
      },
      date (val) {
          this.$emit('change', val)
      }
    },
    computed: {
    computedDateFormatted () {
        return this.formatDate(this.date)
        },
        isTech() {
          return this.$store.state.user.isTech;
    },
  },
    methods: {
      save (date) {
        this.$refs.menu.save(date)
        },

      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
      },    },
    mounted() {
        if (this.$props.birthDate) {
            this.date = this.$props.birthDate;
        }
    }
  }
</script>