<template>
  <v-card class="mb-7">
    <v-toolbar flat dense>
        <v-toolbar-title>            
            {{ heading }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <slot name="tools">
        
        </slot>
      </v-toolbar>
      <v-divider></v-divider>
      <div style="height: 2px">
      <v-progress-linear
      :active="loading"
        :indeterminate="loading"
      color="secondary"      
    ></v-progress-linear>
    </div>
     <div class="pa-4">
            <slot/>
       </div>     
       <v-card-actions>
         <v-spacer/>
       <slot name="actions">

       </slot>
       </v-card-actions>
  </v-card>
</template>

<script>

  export default {
    name: 'BaseCard',
    props: {
      heading: {
        type: String,
        default: '',
      },
      loading: {
        type: Boolean,
        default: false
      }
    },
    data:  () => ({
        
    }),
    methods:{
     
    }
  }
</script>