import services from "@/services";

const sessionModule = {
  namespaced: true,
  state: {
    inpatientSession: {},
    outpatientSession: {},
    loading: false
  },
  getters: {
    inpatientSession: state => state.inpatientSession,
    outpatientSession: state => state.outpatientSession,
    loading: state => state.loading
  },
  actions: {
    async get_outpatient_session(context, outpatientSessionId) {
        context.commit("loading", true);

      return services.patientService
        .getOutpatientSession(outpatientSessionId)
        .then(response => {
            context.commit("set_outpatient_session", response);
        })
        .finally(() => {
            context.commit("loading", false);
        });
    },
    async update_outpatient_session(context, outpatientSession) {
        context.commit("loading", true);

      return services.patientService
        .updateOutpatientSession(outpatientSession)
        .then(response => {
          context.commit("set_outpatient_session", response);
        })
        .finally(() => {
            context.commit("loading", false);
        });
    },
    async get_inpatient_session(context, inpatientSessionId) {
        context.commit("loading", true);

      return services.patientService
        .getInpatientSession(inpatientSessionId)
        .then(response => {
          context.commit("set_inpatient_session", response);
        })
        .finally(() => {
            context.commit("loading", false);
        });
    },
    async update_inpatient_session(context, inpatientSession) {
        context.commit("loading", true);

      return services.patientService
        .updateInpatientSession(inpatientSession)
        .then(response => {
          context.commit("set_inpatient_session", response);
        })
        .finally(() => {
            context.commit("loading", false);
        });
    },
  },
  mutations: {
    set_outpatient_session(state, response) {
      state.outpatientSession = response;
    },
    set_inpatient_session(state, response) {
        state.inpatientSession = response;
      },
      loading(state, on) {
          state.loading = on;
      }
  }
};

export default sessionModule;

