

function ToPageModel(options) {
    var page = {
        perPage: 0,
        currentPage: 0,
        sortBy: [],
        sortDesc: [],
      };

      if (!options) {      
        page.currentPage = 1;
        page.perPage = 10;
        page.sortBy = [];
        page.sortDesc = [];
      } else {
        page.currentPage = options.page;
        page.perPage = options.itemsPerPage;  
        page.sortBy = options.sortBy;
        page.sortDesc = options.sortDesc;
      }  
//   page.sortBy = options.sortBy;
//   page.sortDesc = options.sortDesc;

  return page;
}

export { ToPageModel }
