import Vue from "vue";
import Vuex from "vuex";
import * as modules from "@/modules";
import createPersistedState from "vuex-persistedstate";
//import Cookies from "js-cookie";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    Sidebar_drawer: null,
    Customizer_drawer: false,
    progress: 0    
  },
  modules: {
    auth: modules.authenticationModule,
    user: modules.userModule,
    dashboard: modules.dashboardModule,
    admin: modules.adminModule,
    snack: modules.snackModule,
    patient: modules.patientModule,
    theme: modules.themeModule,
    session: modules.sessionModule
  },
  mutations: {
    SET_SIDEBAR_DRAWER(state, payload) {
      state.Sidebar_drawer = payload;
    },
    SET_CUSTOMIZER_DRAWER(state, payload) {
      state.Customizer_drawer = payload;
    },
  },
  actions: {},
  getters: {
    progress: function(state) {
      return state.progress;
    }
  },
});
