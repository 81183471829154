import services from "@/services";
import { ToPageModel } from "./Models/PageModel";
import { ToSearchPageRequest } from "./Models/SearchPageRequest";
const adminModule = {
  namespaced: true,
  state: {
    availableRoles: [],
    users: [],
    patients: [],
    loadingData: false
  },
  getters: {
    availableRoles: state => state.availableRoles,
    availableUsers: state => state.users,
    availablePatients: state => state.patients,
    loadingData: (state) => state.loadingData
  },
  actions: {
    get_available_roles(context) {
      return services.adminService.getAvailableRoles().then(response => {
        context.commit("set_available_roles", response);
        return response;
      });
    },
    register_user(context, request) {
      return services.adminService.registerUser(request).then(response => {
        return response;
      });
    },
    async get_all_users(context, dataTablePaging) {

      
      var page = ToPageModel(dataTablePaging.options);

      page.sortBy = "";
      page.sortDesc = true;

      var request = ToSearchPageRequest(dataTablePaging.searchText, page);
      
      context.commit("set_loading_data", true);
      return await services.adminService.getAllUsers(request).then(response => {
        context.commit("set_all_users", response);
        return response;
      }).finally(() => {
        context.commit("set_loading_data", false);
      });
    },
    get_all_patients(context) {
      var requestModel = {
        pageModel: {
          perPage: 111,
          currentPage: 1,
          sortBy: "",
          descending: true
        },
        searchText: ""
      };

      return services.patientService
        .getPatients(requestModel)
        .then(response => {
          context.commit("set_all_patients", response);
          return response;
        });
    }
  },
  mutations: {
    set_available_roles(state, response) {
      state.availableRoles = response;
    },
    set_all_users(state, users) {
      state.users = users;
    },
    set_all_patients(state, patients) {
      state.patients = patients.results;
    },
    set_loading_data(state, on) {
      state.loadingData = on;
    }
  }
};

export default adminModule;
