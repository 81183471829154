import baseService from "@/services/base.service.js";

const dashboardService = {
  async getActiveTechnicians() {
    return await baseService.get(`Dashboard/GetActiveTechnicians`);
  },
  async GetAllActiveSessions(request) {
    return await baseService.post(`Dashboard/getallactivesessions`,request);
  },
  async GetSessionBreakdown() {
    return await baseService.get(`Dashboard/GetSessionStatusCounts`);
  },
    async SendNotificationForSessionId(request) {
    return await baseService.post(`Dashboard/SendNotificationForSession?sessionId`, request);
  }
};

export default dashboardService;
