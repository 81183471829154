import authenticationService from "@/services/authentication.service.js"
import baseService from "@/services/base.service.js"
import userService from "@/services/user.service.js"
import dashboardService from "@/services/dashboard.service.js"
import adminService from "@/services/admin.service.js"
import patientService from "./patient.service"
import sessionlogService from "./sessionlog.service"
import sessionDetailService from './sessiondetail.service'
import outpatientService from "./outpatient.service"
import inpatientService from "./inpatient.service"
import patientlogService from "./patientlog.service"

export default {
    authenticationService,
    baseService,
    userService,
    dashboardService,
    adminService,
    patientService,
    sessionlogService,
    sessionDetailService,
    outpatientService,
    inpatientService,
    patientlogService
}