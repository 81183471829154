import baseService from "@/services/base.service.js";
/* eslint-disable */
const outpatientService = {
  async create(outpatient) {
    return await baseService.post(`OutpatientSession/Create`,outpatient);
    },
    async getOutpatientSessionsWithoutSchedules(date) {
        return await baseService.get(`OutpatientSession/GetOutpatientSessionsWithoutSchedules?date=${date}`);
    },
};

export default outpatientService;
