import baseService from "./base.service.js"

    const userService = {
        async getUser() {
            return await baseService.post("Login/GetLoggedInUser");
        },
        async getUserPic() {
            return await baseService.get("user/GetUserPic");
        },
        async getUserRoles() {
            return await baseService.get("user/GetUserRoles");
        },
    }
    
    export default userService;