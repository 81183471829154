/* eslint-disable */
/* jshint ignore:start */
import axios from "axios";
import router from "@/router";
import store from "@/store";
import vm from "vue";
import qs from "qs";

const instance = axios.create({
  baseURL: "/api",
  timeout: 90000,
  withCredentials: true,
  headers: {
    "Access-Control-Allow-Origin": "*",
    'Content-Type': 'application/json'
  },
  paramsSerializer(params) {
    return qs.stringify(params, { encode: false });
  }
});

instance.interceptors.request.use(
  function(config) {
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function(response) {

    return response;
  },
    function (error) {
    var errorStatus = parseInt(error.response.status,0);
    if (errorStatus == 401) {
      router.push('/login')
    }
    return Promise.reject(error);
  }
);

const baseService = {
  async get(url, parameters) {
    const config = {};

    if (parameters) {
      config.params = parameters;
    }

    return new Promise((resolve, reject) => {
      instance
        .get(url, config)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.status);
        });
    });
    },
    async getFile(url, filename) {
        const config = {};
        return new Promise((resolve, reject) => {
            instance
                .get(url, {
                    responseType: 'arraybuffer',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/pdf'
                    }
                })                
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data])); 
                    const link = document.createElement('a');
                    link.href = url;
                    var rightNow = new Date();
                    var res = rightNow.toISOString().slice(0, 10).replace(/-/g, "");
                    link.setAttribute('download', "patient_log_" + res + ".pdf"); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                }).then(() => {
                    resolve();
                })
                .catch(error => {
                    reject(error.status);
                });
        });
    },
  async delete(url, parameters) {
    const config = {};

    if (parameters) {
      config.params = parameters;
    }

    return new Promise((resolve, reject) => {
      instance.delete(
        url,
        config
          .then(response => {
            resolve(response.data);
          })
          .catch(response => {
            reject(response.status);
          })
      );
    });
  },
  async put(url, parameters) {
    const config = {};

    if (parameters) {
      config.params = parameters;
    }

    return new Promise((resolve, reject) => {
      instance.put(
        url,
        config)
          .then(response => {
            resolve(response.data);
          })
          .catch(response => {
            reject(response.status);
          })
      ;
    });
  },
  async post(url, data) {
    const config = {
      onUploadProgress: progressEvent => {
        store.commit(
          "SET_PROGRESS",
          parseInt(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          )
        );
      }
    };

    return new Promise((resolve, reject) => {
      instance
        .post( url, data)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export default baseService;
