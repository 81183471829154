import services from "@/services"
import router from "@/router";

const defaultState = {
  isAuthenticated: false,
  isLockedOut: false,
  isNotAllowed: false,
  requiresTwoFactor: false,
  loading: false
}

const authenticationModule = {
  namespaced: true,
  state: defaultState,
  getters: {
    isAuthenticated: state => state.isAuthenticated,
    loading: state => state.loading
  },
    actions: {
        validate_credentials(context, credentials) {
            return new Promise((resolve, reject) => {
                // Do something here... lets say, a http call using vue-resource
                this.loading = true;
                services.authenticationService.validateLogin(credentials, context).then(response => {
                    // http success, call the mutator and change something in state
                    resolve(response);  // Let the calling function know that http is done. You may send some data back
                }, error => {
                    // http failed, let the calling function know that action did not work out
                    reject(error);
                }).finally(() => this.loading = false);
            })
        },



        
    login(context, credentials) {
          this.loading = true;
          
        return services.authenticationService.login(credentials, context).then(response => {
          context.commit("set_login_two_factor", response);        

      }).finally(() => this.loading = false);
    },
    logout(context) {
      this.loading = true;
        context.commit("set_logout");;
      return services.authenticationService.logout(context).then(response => {
        
        return response;
      }).finally(() => this.loading = false);
    }
  },
  mutations: {
      set_login(state, response) {
      state.isAuthenticated = response.succeeded;
      state.isLockedOut = response.isLockedOut;
      state.isNotAllowed = response.isNotAllowed;
      state.requiresTwoFactor = response.requiresTwoFactor;      
      },
      set_login_two_factor(state, response) {
          if (response == true) {
              state.isAuthenticated = true;
              state.isLockedOut = false;
              state.isNotAllowed = false;
              state.requiresTwoFactor = true;
              router.push("/dashboard");
          }
      },
      set_logout(state) {
          state.isAuthenticated = false;
          console.log(state);
      
    }
  }
};

export default authenticationModule;
