<template>
    <v-btn
                  large
                  depressed
                  :class="[ buttonClasses, 'v-btn--text']"                  
                  :disabled="!valid || loggingIn"
                  submit
                  @click="submit"
                >
                  {{text}}
                </v-btn>
</template>
<script>
export default {
    props: {
        loading: {
        type: Boolean,
        default: false
      },
      valid: {
        type: Boolean,
        default: false
      },
      loggingIn: {
        type: Boolean,
        default: false
      },
      text: {
        type: String,
        default: ''
      },
      buttonClasses: {
          type: String,
          default: 'primary--text'
      },
    },
    computed: {
        primaryColor() {            
            return this.$store.getters.TopbarColor;
        }
    }
}
</script>