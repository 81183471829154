<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent :width="width">
      <v-card dense>
        <v-card-title :class="titleColor" class=" white--text">
          <slot name="title"></slot>
          <v-spacer></v-spacer>
          <v-btn
            fab
            class="primary"
            x-small
            @click="$emit('cancel')"
            :disabled="loading"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <slot name="body"></slot>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <slot name="actions"></slot>
          <v-btn
            color="blue darken-1"
            text
            v-if="showCancel"
            @click="$emit('cancel')"
            :disabled="loading"
            >{{cancelText}}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "BaseModal",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "600",
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
    cancelText: {
      type: String,
      default: "Cancel",
    },
    titleColor: {
      type: String,
      default: "black",
    }
  },
};
</script>
