const snackModule = {
    namespaced: true,
    state: { 
        attack: ''
    },
    getters: {
        snackAttack: state => state.attack,
    },
    mutations: {
        set_snack(state, response) {
            state.attack = response;
        }
    }
};

export default snackModule;