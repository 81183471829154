import baseService from "@/services/base.service.js";

const adminService = {
  async getAvailableRoles() {
    return await baseService.get(`Admin/GetAvailableRoles`);
  },
  async registerUser(request) {
    return await baseService.post(`Admin/Register`, request);
  },
  async getAllUsers(request) {
    return await baseService.post(`Admin/GetAllUsers`, request);
  },
  async getAllPatients() {
    return await baseService.get(`Patient/GetAll`);
  },
  async updateUser(request) {
    return await baseService.post("Admin/UpdateUser", request);
    },
    async unlockUser(request) {
        return await baseService.post("Admin/UnlockUser", request);
    },
  async deleteUser(request) {
    return await baseService.post("Admin/DeleteUser", request);
  },
  async downloadPatientLogs(patientId) {
    return await baseService.getFile(`Admin/ExportLogs?patientId=${patientId}`, patientId);
    },
    
};

export default adminService;
