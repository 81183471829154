import Vuetify from "../plugins/vuetify";

const themeModule = {
  namespaced: true,
  state: {
    SidebarColor: {"alpha":1,"hex":"#242961","hexa":"#242961FF","hsla":{"h":235.0413223140496,"s":0.4598540145985403,"l":0.2603,"a":1},"hsva":{"h":235.0413223140496,"s":0.63,"v":0.38,"a":1},"hue":235.0413223140496,"rgba":{"r":36,"g":41,"b":97,"a":1}},
    TopbarColor: {"alpha":1,"hex":"#080808","hexa":"#080808FF","hsla":{"h":238.01652892561984,"s":0.03448275862068967,"l":0.032222222222222215,"a":1},"hsva":{"h":238.01652892561984,"s":0.06666666666666667,"v":0.033333333333333326,"a":1},"hue":238.01652892561984,"rgba":{"r":8,"g":8,"b":8,"a":1}},
    SideBarBackground: "",
    isDarkMode: false,
    PrimaryColor: {"alpha":1,"hex":"#A38849","hexa":"#A38849FF","hsla":{"h":41.65289256198347,"s":0.38248847926267293,"l":0.4629333333333333,"a":1},"hsva":{"h":41.65289256198347,"s":0.5533333333333333,"v":0.64,"a":1},"hue":41.65289256198347,"rgba":{"r":163,"g":136,"b":73,"a":1}}
  },
  mutations: {
    SET_SIDEBAR_COLOR(state, payload) {
      state.SidebarColor = payload;
    },
    SET_TOPBAR_COLOR(state, payload) {
      state.TopbarColor = payload;
    },
    SET_DARK_MODE(state, payload) {
        Vuetify.framework.theme.dark = payload;
        state.isDarkMode = payload;
    },
    SET_PRIMARY_COLOR(state, payload) {
        state.PrimaryColor = payload;

        if (state.isDarkMode) {
          Vuetify.framework.theme.themes.dark.primary = payload;
        } else {
          Vuetify.framework.theme.themes.light.primary = payload;
        }
    },
  },
  actions: {},
  getters: {
    PrimaryColor: function(state) {
      if (state.PrimaryColor) {
        if (state.isDarkMode) {
          Vuetify.framework.theme.themes.dark.primary = state.PrimaryColor.hex;
        } else {
          Vuetify.framework.theme.themes.light.primary = state.PrimaryColor.hex;
        }
        return state.PrimaryColor.hex;
      } else {
        if (state.isDarkMode) {
          return Vuetify.framework.theme.themes.dark.primary;
        } else {
          return Vuetify.framework.theme.themes.light.primary;
        }
      }
    },
    SidebarColor: function(state) {
      return state.SidebarColor.hex;
    },
    TopbarColor: function(state) {
      return state.TopbarColor.hex;
    },
    isDarkMode: function(state) {
      if (Vuetify.framework.theme.dark) {
        return Vuetify.framework.theme.dark;
      } else {
        Vuetify.framework.theme.dark = state.isDarkMode;
        return state.isDarkMode;
      }
    },
  },
};

export default themeModule;
