import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import Vuebar from 'vuebar'
import './plugins/base'
import VueSkycons from 'vue-skycons';
import ImageUploader from 'vue-image-upload-resize'
import VeeValidate from "vee-validate";
import VueMask from 'v-mask'
import moment from 'moment-timezone'
moment.tz.add([
  'America/Chicago|CST CDT|60 50|01010101010101010101010|1O0U0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|92e5',
])
moment.tz.setDefault('America/Chicago')
Vue.prototype.$moment = moment


import axios from 'axios'
import VueAxios from "vue-axios";
import VueLogger from "vuejs-logger";
//const isProduction = process.env.NODE_ENV !== "development";
var isProduction  = false;
import DatetimePicker from 'vuetify-datetime-picker'
// (Optional) import 'vuetify-datetime-picker/src/stylus/main.styl'
const options = {
  isEnabled: !isProduction,
  logLevels: ["debug", "info", "warn", "error", "fatal"],
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true
};
Vue.use(DatetimePicker)

Vue.use(VueLogger, options);
Vue.use(VueAxios, axios);
Vue.use(VeeValidate);
Vue.config.productionTip = false
Vue.use(Vuebar);
Vue.use(ImageUploader);
Vue.use(VueSkycons, {
  color: '#1e88e5'
});

Vue.use(VueMask)

new Vue({
    vuetify,
    store,
    router,
    render: h => h(App)
}).$mount('#app')
