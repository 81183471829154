import services from "@/services"
import { ToPageModel } from "./Models/PageModel";
import { ToSearchPageRequest } from "./Models/SearchPageRequest";
import store from "@/store"

const dashboardModule = {
    namespaced: true,
    state: { 
        activeSessions: [],
        sessionBreakdown: {},
    },
    getters: {
        activeSessions: state => state.activeSessions,
        sessionBreakdown: state => state.sessionBreakdown
    },
    actions: {
        get_active_sessions(context, dataTablePaging) {
            var page = ToPageModel(dataTablePaging.options);
            var request = ToSearchPageRequest(dataTablePaging.searchText, page);
            return services.dashboardService.GetAllActiveSessions(request).then(response => {
                return response;
            });
        },
        get_session_breakdown(context) {        
            return services.dashboardService.GetSessionBreakdown().then(response => {
                context.commit("set_session_breakdown", response);
                return response;
            });
        },
        send_notification(context, sessionId) {
            return services.dashboardService.SendNotificationForSessionId(sessionId).then(() => {
                store.commit(
                    "snack/set_snack",
                    "Notification successfully sent."
                  )
            }).catch(()=> {
                store.commit(
                    "snack/set_snack",
                    "Failed to send notification."
                  );
            });
        }
    },
    mutations: {
        set_active_sessions(state, response) {
            state.activeTechStats = response;
        },
        set_session_breakdown(state, breakdown) {
            state.sessionBreakdown = breakdown;
        }
    }
};

export default dashboardModule;