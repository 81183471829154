import baseService from "@/services/base.service.js";
/* eslint-disable */
const sessionDetailService = {
  async create(sessionDetail) {
    return await baseService.post(`SessionDetail/Create`,sessionDetail);
    },
    async createbatch(sessionDetails) {
        return await baseService.post(`SessionDetail/CreateBatch`, sessionDetails);
    },
  async getAll(sessionId) {
    return await baseService.get(`SessionDetail/getall?sessionId=${sessionId}`);
  },
  async update(sessionDetail) {
    return await baseService.post("SessionDetail/update", sessionDetail);
  },
  async delete(sessionDetail) {
    return await baseService.post(`SessionDetail/delete`, sessionDetail);
  },
  async getAvailableTechnicians(outpatientSessionId) {
    return await baseService.get(`SessionDetail/GetAvailableTechnicians?outpatientSessionId=${outpatientSessionId}`);
  },
  async getTechnicianTypes() {
    return await baseService.get("Lookup/GetTechnicianTypes")
  },
  async getSessionDetailForCalendar(startTime, endDate) {
    return await baseService.get(`SessionDetail/GetSessionDetailForCalendar?startTime=${startTime}&endDate=${endDate}`);
  },
  async getCalendarDetail(sessionDetailId) {
    return await baseService.get(`SessionDetail/GetCalendarDetail?sessionDetailId=${sessionDetailId}`);
  }
};

export default sessionDetailService;
