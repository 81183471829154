import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";
import store from './store'
import { mapGetters } from "vuex";

Vue.use(Router);

//export default new Router({
//  mode: "history",
//  base: process.env.BASE_URL,
//  // This is for the scroll top when click on any router link
//  scrollBehavior: (to, from, savedPosition) => {
//    let scrollTo = 0;

//    if (to.hash) {
//      scrollTo = to.hash;
//    } else if (savedPosition) {
//      scrollTo = savedPosition.y;
//    }

//    return goTo(scrollTo);
//    },


const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/",
            redirect: "dashboard",
            component: () => import("@/layouts/full-layout/Layout"),
            children: [
                {
                    name: "dashboard",
                    path: "dashboard",
                    meta: { requiresAuth: true },
                    component: () => import("@/views/dashboard/dashboard.vue")
                },
                {
                    name: "profile",
                    path: "profile",
                    meta: { requiresAuth: true },
                    component: () => import("@/views/profile/profile.vue")
                },
                {
                    name: "users",
                    path: "administration/users",
                    meta: { requiresAuth: true },
                    component: () => import("./views/administration/users/index.vue")
                },
                {
                    name: "user",
                    path: "administration/user/:id",
                    meta: { requiresAuth: true },
                    component: () => import("@/views/administration/users/userUpdate.vue")
                },
                {
                    name: "Patients",
                    path: "patients",
                    meta: { requiresAuth: true },
                    component: () => import("@/views/patients/patients.vue")
                },
                {
                    name: "Patient",
                    path: "patient/:id",
                    meta: { requiresAuth: true },
                    component: () => import("@/views/patient/index.vue"),
                    meta: {
                        id: 2
                    }
                },
                {
                    name: "schedule",
                    path: "schedule",
                    meta: { requiresAuth: true },
                    component: () => import("@/views/schedule/index.vue")
                },

            ]
        },

        {
            path: "/",
            component: () => import("@/layouts/blank-layout/Blanklayout"),
            children: [
                {
                    name: "login",
                    path: "login",                    
                    component: () => import("@/views/authentication/login")
                },
                {
                    name: "logout",
                    path: "logout",
                    component: () => import("@/views/authentication/logout")
                }
            ]
        },
        {
            path: "*",
            component: () => import("@/views/Error")
        }
    ],
    scrollBehavior(to, from, savedPosition) {
        // return desired position
        let scrollTo = 0;

        if (to.hash) {
            scrollTo = to.hash;
        } else if (savedPosition) {
            scrollTo = savedPosition.y;

            return goTo(scrollTo);
        }
    }
    
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(route => route.meta.requiresAuth) && store.getters['auth/isAuthenticated']) {
        next();
    }
    else if (!to.matched.some(route => route.meta.requiresAuth))
        next();
    else 
        next({ path: '/login' });
});



export default router;