import baseService from "@/services/base.service.js";

const sessionlogService = {
  async getCategoryTypes() {
    return await baseService.get(`Lookup/GetCategoryTypes`);
  },
  async getMonitorTypes() {
    return await baseService.post(`Lookup/GetMonitorTypes`);
  },
  async createLogs(request) {
    return await baseService.post(`SessionLog/CreateOutpatientLog`,request);
  },
  async getLogs(request) {
    return await baseService.post(`SessionLog/GetAllByPatientId`, request);
  },
};

export default sessionlogService;
