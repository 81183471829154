<template>
  <div class="text-center">
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: "BaseLoader",
  data: () => {
    return {};
  },
  props: {
    loader: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
      loader(value){
      }
  }
};
</script>
